<template>
  <div class="container">
    <!-- <h3 class="text-transparencia fw-600 mb-3">
          <layout-breadcrumb v-bind:pagina="'NOSOTROS'"></layout-breadcrumb>
        </h3> -->
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <section class="pb-5">
      <div class="">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="font-weight-800  text-transparencia">
              <!-- <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i></a> -->
              <span class="">NOSOTROS</span>
            </h2>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-5">
            <h2 class="mb-4 fw-600 text-transparencia text-center">
              Misión
            </h2>
            <p class="text-justify text-dark" v-html="datos.mision"></p>
            <div class="text-center mb-3">
              <a href="nosotros/transparencia" class="btn btn-transparencia ">Conoce más de nosotros</a>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <img :src="`${filesUrl + datos.rutaImagen}`" class="cr-po image-round-all" data-provide="lightbox" v-if="datos.tipoMultimedia == 'IMG'" />
            <div class="video-wrapper ratio-16x9" v-if="datos.tipoMultimedia == 'VID'">
              <iframe title="video" :src="datos.rutaVideo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-5">
      <div class="container">
        <h5 class="fs-16 font-weight-600">Conoce al capital humano que conforma esta asociación, elige una categoría.</h5>
        <div class="row justify-content-between text-center">
          <div class="col-md-4 mt-2">
            <button class="btn btn-transparencia btn-block transition-hover px-3 rounded-pill" @click="mostrarPanel('CDR')">Consejo Directivo 2020-2023</button>
          </div>
          <div class="col-md-4 mt-2">
            <button class="btn btn-transparencia  btn-block  transition-hover px-3 rounded-pill" @click="mostrarPanel('ASA')">Asamblea de Asociados</button>
          </div>
          <div class="col-md-4 mt-2">
            <button class="btn btn-transparencia  btn-block  transition-hover px-3 rounded-pill" @click="mostrarPanel('EQU')">Equipo</button>
          </div>
        </div>
        <div class="row justify-content-center pt-5 " v-if="esConsejo">
          <template v-for="(persona, index) in directivos">
            <div class="col-md-5 col-sm-6 col-xs-12 col-lg-3 mt-2" :key="index">
              <div class="card d-block card-owework">
                <div class="card-img-top" v-if="persona.rutaImagen != null && persona.rutaImagen" :data-overlay-light="persona.estado == 'CON' ? '6' : '1'">
                  <img class="img-fluid w-100 image-round-top" :src="`${fileUrl(persona.rutaImagen)}`" :alt="persona.nombres" />
                  <div class="badges w-100 text-right" v-if="persona.estado == 'CON'">
                    <span class="badge badge-transparencia font-weight-600">Con licencia</span>
                  </div>
                </div>
                <div class="card-body" style="height:13.7rem !important;">
                  <p class="text-center mb-0 text-transparencia" v-if="persona.cargo != null && persona.cargo != ''">{{ persona.cargo }}</p>
                  <div style="">
                    <h5 class="card-title mb-0 fw-600 text-center">{{ persona.nombres }}</h5>

                  </div>
                  <p class="text-justify lh-135 text-dark fs-14 mt-3 mb-3" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''" style="height:5.5rem !important;overflow:hidden">
                    {{ persona.descripcionCorta }}
                  </p>
                </div>
                <div class="card-footer text-right">
                  <a class="fs-13 fw-600 anim-link-2 anim-link-2-primary" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''" href="#" data-toggle="modal" data-target="#modal-minimal" @click="mostrarDatos(persona)"><i class="fa fa-search-plus"></i> Leer más</a>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="row justify-content-between text-center  mt-5" v-if="esConsejo">
          <div class="col-md-4 col-xs-12"></div>
          <div class="col-md-4 col-xs-12 ">
            <button class="btn btn-transparencia btn-transparencia-vocales btn-block px-3 rounded-pill">Vocales</button>
          </div>
          <div class="col-md-4 col-xs-12"></div>
        </div>
        <div class="row justify-content-center pt-5" v-if="esConsejo">
          <template v-for="(persona, index) in vocales">
            <div class="col-xs-12 col-sm-6 col-md-5 col-lg-3 mt-4" :key="index">
              <div class="card d-block card-owework">
                <div class="card-img-top" v-if="persona.rutaImagen != null && persona.rutaImagen" :data-overlay-light="persona.estado == 'CON' ? '6' : '1'">
                  <img class="img-fluid w-100  image-round-top" :src="`${fileUrl(persona.rutaImagen)}`" :alt="persona.nombres" />
                  <div class="badges w-100 text-right" v-if="persona.estado == 'CON'">
                    <span class="badge badge-transparencia font-weight-600">Con licencia</span>
                  </div>
                </div>
                <div class="card-body" style="height:10.7rem !important;">
                  <div style="height:3.8rem !important;">
                    <h5 class="card-title mb-0 fw-600 text-center">{{ persona.nombres }}</h5>
                  </div>
                  <p class="text-justify lh-135 fs-14 text-dark mt-3 mb-4" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''" style="height:5.5rem !important;overflow:hidden">
                    {{ persona.descripcionCorta }}
                  </p>
                </div>
                <div class="card-footer text-right">
                  <a class="fs-13 fw-600 anim-link-2 anim-link-2-primary" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''" href="#" data-toggle="modal" data-target="#modal-minimal" @click="mostrarDatos(persona)"><i class="fa fa-search-plus"></i> Leer más</a>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="row justify-content-center pt-5" v-if="esAsamblea">
          <template v-for="(persona, index) in asambleas">
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12 m-3" :key="index">
              <div class="card d-block border-0" style="background-color:transparent !important;">
                <div class="card-img-top text-center" v-if="persona.rutaImagen != null && persona.rutaImagen" :data-overlay-light="persona.estado == 'CON' ? '6' : '1'">
                  <img class="img-fluid image-round-all" :src="`${fileUrl(persona.rutaImagen)}`" :alt="persona.nombres" style="height: auto;width: auto;" />
                  <div class="badges w-100 text-right" v-if="persona.estado == 'CON'" style="padding: 0.25rem 0.55rem;">
                    <span class="badge badge-transparencia font-weight-600">Con licencia</span>
                  </div>
                </div>
                <div class="card-body p-2">
                  <span class="card-title fs-14 mb-0 w-100 font-weight-600 text-center text-transparencia" style="display:block">{{ persona.nombres }}</span>
                  <p class="text-center text-transparencia" v-if="persona.cargo != null && persona.cargo != ''">{{ persona.cargo }}</p>
                  <p class="text-justify lh-135 text-small text-dark font-weight-light mt-3 mb-4" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''">
                    {{ persona.descripcionCorta }}
                  </p>
                  <a class="clink-read-more-left fs-13 fw-600 anim-link-2 anim-link-2-primary" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''" href="#" data-toggle="modal" data-target="#modal-minimal" @click="mostrarDatos(persona)"><i class="fa fa-search-plus"></i> Leer más</a>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="row justify-content-center pt-5" v-if="esEquipo">
          <template v-for="(persona, index) in equipo">
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12 m-3" :key="index">
              <div class="card d-block border-0" style="background-color:transparent !important;">
                <div class="card-img-top text-center" v-if="persona.rutaImagen != null && persona.rutaImagen" :data-overlay-light="persona.estado == 'CON' ? '6' : '1'">
                  <img class="img-fluid image-round-all" :src="`${fileUrl(persona.rutaImagen)}`" :alt="persona.nombres" style="height: auto;width: auto;" />
                  <div class="badges w-100 text-right" v-if="persona.estado == 'CON'" style="padding: 0.25rem 0.55rem;">
                    <span class="badge badge-transparencia font-weight-600">Con licencia</span>
                  </div>
                </div>
                <div class="card-body p-2">
                  <span class="card-title fs-14 mb-0 w-100 font-weight-600 text-center text-transparencia" style="display:block">{{ persona.nombres }}</span>
                  <p class="text-center fs-13 text-transparencia" v-if="persona.cargo != null && persona.cargo != ''">{{ persona.cargo }}</p>
                  <p class="text-justify lh-135 text-small text-dark font-weight-light mt-3 mb-4" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''">
                    {{ persona.descripcionCorta }}
                  </p>
                  <a class="clink-read-more-left fs-13 fw-600 anim-link-2 anim-link-2-primary" v-if="persona.descripcionCorta != null && persona.descripcionCorta != ''" href="#" data-toggle="modal" data-target="#modal-minimal" @click="mostrarDatos(persona)"><i class="fa fa-search-plus"></i> Leer más</a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>

    <div class="modal fade" id="modal-minimal" tabindex="-1" role="dialog" v-if="mostrarModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content  card-owework">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="team-member mt-4">
              <div class="" v-if="datosModal.rutaImagen != null && datosModal.rutaImagen">
                <img class="img-fluid w-100 rounded" :src="`${fileUrl(datosModal.rutaImagen)}`" :alt="datosModal.nombres" />
              </div>
              <div class="card border-0  card-owework">
                <div class="card-body text-center p-3">
                  <h4 class="fw-600 mb-0">{{ datosModal.nombres }}</h4>
                  <p class="text-transparencia" v-if="datosModal.cargo != null && datosModal.cargo != ''">{{ datosModal.cargo }}</p>
                  <p class="text-transparencia  fs-18 mt-3">
                    <small class="text-justify text-transparencia mb-0" v-if="datosModal.descripcion != null && datosModal.descripcion != ''" v-html="datosModal.descripcion"></small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb.vue";
export default {
  name: "nosotros",
  metaInfo: {
    title: "Nosotros",
  },
  components: {
    layoutBreadcrumb,
  },
  data: () => ({
    datos: {},
    esConsejo: true,
    esAsamblea: false,
    esEquipo: false,
    personas: [],
    directivos: [],
    asambleas: [],
    equipos: [],
    vocales: [],
    mostrarModal: false,
    datosModal: {},
  }),
  methods: {
    mostrarPanel: function (codigo) {
      if (codigo == "CDR") {
        this.esConsejo = true;
        this.esAsamblea = false;
        this.esEquipo = false;
      }
      if (codigo == "ASA") {
        this.esConsejo = false;
        this.esAsamblea = true;
        this.esEquipo = false;
      }
      if (codigo == "EQU") {
        this.esConsejo = false;
        this.esAsamblea = false;
        this.esEquipo = true;
      }
    },
    getFilterData: function (lista, codigo) {
      var resultado = lista.filter((x) => x.tipoPersona == codigo);
      if (resultado != null && resultado.length > 0) {
        return resultado;
      }
      return [];
    },
    mostrarDatos: function (datos) {
      this.datosModal = null;
      this.mostrarModal = false;
      this.$nextTick(() => {
        this.datosModal = datos;
        this.mostrarModal = true;
      });
    },
    fetchDatos: async function () {
      await this.$http.get(this.apiRoute("Common", "GetNosotrosData")).then(function (response) {
        if (!response.body.error) {
          this.datos = response.body.data;
        }
      });
    },
    fetchPersonas: async function () {
      await this.$http.get(this.apiRoute("Common", "ListPersonasAll")).then(function (response) {
        if (!response.body.error) {
          this.personas = response.body.data;
          this.directivos = this.getFilterData(this.personas, "CDR");
          this.asambleas = this.getFilterData(this.personas, "ADA");
          this.equipo = this.getFilterData(this.personas, "EQU");
          this.vocales = this.getFilterData(this.personas, "VOC");
        }
      });
    },
  },
  mounted: async function () {
    await this.fetchDatos();
    await this.fetchPersonas();

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Nosotros", url: "", icon: "", active: true },
    ];
  },
};
</script>
